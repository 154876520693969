<template>
  <div class="layout-root">
    <div
      v-if="theme === 'subpage'"
      class="background"
      @click="navigateToParentPage"
    />

    <div class="container">
      <div class="main">
        <div class="main__page">
          <div class="main__menu">
            <ReflectTitle
              class="main__title"
              :text="$t('settings.title')"
            />
            <SettingsNavMenu v-if="theme !== 'subpage'" />
          </div>
          <div
            class="main__content"
            :class="{
              'main__content--subpage': theme === 'subpage'
            }"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>

    <Teleport
      v-if="isRouteChangeConfirmationModalOpen"
      to="#action"
    >
      <ReflectModal
        :title-message="$t('navigationModal.title')"
        :action-message="$t('navigationModal.action')"
        :cancel-message="$t('navigationModal.cancel')"
        action-button-theme="quaternary"
        @action="confirmRouteChange"
        @cancel="abortRouteChange"
      >
        <p class="edition-exit-modal">
          {{ $t('navigationModal.description1') }}
        </p>
        <p class="edition-exit-modal">
          {{ $t('navigationModal.description2') }}
        </p>
      </ReflectModal>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import type { SlotsType } from 'vue'
import { useLayoutStore } from '~/stores/layout'
import { useRouterStore } from '~/stores/router'
import { navigateToParentPage } from '~/services/router'

export default {
  name: 'SettingsLayout',
  slots: Object as SlotsType<{
    default: {}
  }>,
  setup() {
    return { navigateToParentPage }
  },
  computed: {
    ...mapState(useLayoutStore, ['theme']),
    ...mapState(useRouterStore, ['isRouteChangeConfirmationModalOpen'])
  },
  methods: {
    confirmRouteChange() {
      const { confirmRouteChange } = useRouterStore()
      confirmRouteChange()
    },
    abortRouteChange() {
      const { abortRouteChange } = useRouterStore()
      abortRouteChange()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden auto;
}

.edition-exit-modal {
  width: 480px;
  @include font-text;
  @include font-size($font-size-regular);
  color: $text-secondary;
  text-align: center;

  &:first-of-type {
    margin-bottom: $margin-regular;
  }
}

.background {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: $overlay-background;
  cursor: pointer;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $page-width;
}

.main {
  position: relative;
  padding: $margin-regular 40px $margin-medium;
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;

  .main {
    $menu-width: 320px;
    $content-offset: $menu-width + 80px;

    &__page {
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin-left: $side-width;
      width: calc(100% - $side-width);
      position: relative;
      height: 100%;
    }

    &__menu {
      position: fixed;
      top: $margin-regular;
      bottom: $margin-medium;
      display: flex;
      flex-direction: column;
      width: $menu-width;
    }

    &__title {
      margin-bottom: $margin-regular;
    }

    &__content {
      display: flex;
      justify-self: flex-end;
      flex: 1;
      width: calc(100% - $content-offset);
      margin-left: $content-offset;
      // Size of page title + margin
      padding-top: 70px;
      height: 100%;
      box-sizing: border-box;

      &--subpage {
        width: 100%;
        margin-left: unset;
      }
    }
  }
}
</style>
